<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<!-- 관심인재 리스트 -->
				<div class="list-box">
					<fixTop :inContent="true" fixClass="top-fix">
						<template v-slot>
						</template>
						<template v-slot:conts>
							<div class="tit flex-box">
								<span>기술인재 정보 &gt; 관심인재</span>
								<div class="sub_tit">{{totCnt}}명의 관심인재가 있습니다!</div>
							</div>							
							<!-- <div class="search-box">
								<inputComp v-model="srcFilter.srcTxt" placeholder="기술인재명" @keyup.enter="selectIntPrsList('1')"/>  <div class="search_btn"><img class="search" src="/images/search_btn.png" alt="검색버튼" @click="selectIntPrsList('1')"/></div>
							</div> -->

							<!-- 검색필터 -->
							<div class="tech-search-container">
								<div :class="[searchFlag == true ? 'btn open' : 'btn']" @click="openSearch()">직무분야 찾기</div>
								<!-- 닫힌 상태에서는 open class를 삭제 해주세요 -->
								<div :class="[searchFlag == true ? 'container' : 'container displayNone']">
									<div class="select-area">
										<ul>
										<!-- <li><a class="activated" href="">어플리케이션개발</a></li>
										<li><a class="active" href="">웹프로그램</a></li>
										<li><a href="">응용프로그램</a></li> -->
											<li v-for="(code, index) in searchCodeList" :key="index">
												<a :class="[index == searchClick ? 'active' : [code.count > 0 ? 'activated':''] ]" @click="changeClick(index)">{{code.cdNm}}</a>
											</li>
										</ul>
									</div>
									<div class="result-area" v-if="searchClick == undefined">
										<div style="width=100%; height:100%; display:flex; justify-content:center; align-items:center;">  
											<div><p> &lt; 직무분야를 선택하세요 </p></div>
										</div>
									</div>
									<div class="result-area" v-else>
										<!-- <li><a href="">전체</a></li>
										<li><a class="active" href="">Java</a></li>
										<li><a href="">Python</a></li> -->
										<ul>
											<!-- <li><a @click="allCheck">전체</a></li> -->
											<li v-for="(deta, index) in searchCodeList[searchClick].detailList" :key="index">
												<a :class="[deta.checked ? 'active' : '']" @click="clickDetail(deta, index)">{{deta.cdNm}}</a>
											</li>
										</ul>
									</div>
								</div>
								<div :class="[searchFlag == true ? 'selected-area' : 'selected-area displayNone']">
									<ul>
										<!-- <li>어플리케이션개발 > 백엔드 <button class="btn-delete">삭제</button></li> -->
										<li v-for="(list, index) in srcFilter.searchFilterList" :key="index">{{list.cdNm}}<button class="btn-delete" @click="delDetail(list, index)">삭제</button></li>
									</ul>
									<div class="aside">
										<button class="btn-reset" @click="resetSearchFilter()">초기화</button>
										<button class="btn-search" @click="selectIntPrsList('1', true)"><span>선택 조건 검색</span></button>
									</div>
								</div>
							</div>

							<div class="filter">
								<div class="delete_btn" @click="deleteIndPerson()">삭제</div>
								<div class="filter_box int save">
									<ul>
										<li><img :class="srcFilter.ordCol == 'eval' && srcFilter.sort == 'desc' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('eval', 'desc')">평가등급 높은 순</p><span>ㅣ</span></li>
										<li><img :class="srcFilter.ordCol == 'eval' && srcFilter.sort == 'asc' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('eval', 'asc')">평가등급 낮은 순</p><span>ㅣ</span></li>
										<li><img :class="srcFilter.ordCol == 'tech' && srcFilter.sort == 'desc' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('tech', 'desc')">기술등급 높은 순</p><span>ㅣ</span></li>
										<li><img :class="srcFilter.ordCol == 'tech' && srcFilter.sort == 'asc' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="searchOrder('tech', 'asc')">기술등급 낮은 순</p></li>
									</ul>
								</div>
							</div>
							<!-- 관심인재 리스트 -->
							<div class="Board type3 human tech">
								<div class="check_ft">
                                    <!-- 참여가능여부 -->
                                    <SearchFilter name="참여가능여부" v-model="srcFilter.joinPossList" list="Y:참여가능,N:참여불가능" @change="selectIntPrsList('1', true)" />
                                    <!-- 기술등급 -->
                                    <SearchFilter name="기술등급" v-model="srcFilter.techList" cdId="PRO106" @change="selectIntPrsList('1', true)" />
                                    
                                    <!-- NCS 직무등급
                                    <SearchFilter name="NCS 직무등급" v-model="input.ncsList" list="L1,L2,L3,L4,L5,L6,L7,L8,L9" @change="getList()"/>
                                     -->

                                    <!-- 직무 -->
                                    <!-- <SearchFilter name="직무" v-model="input.dutyList" cdId="PRO119" @change="getList()"/> -->

                                    <!-- 경력 -->
                                    <SearchFilter name="경력" v-model="srcFilter.careerList" cdId="PRO107" @change="selectIntPrsList('1', true)" />

                                    <!-- 학력 -->
                                    <SearchFilter name="학력 - 졸업" v-model="srcFilter.schoolList" cdId="PRO121" @change="selectIntPrsList('1', true)" />
                                </div>
								
								<table class="Board_type3 human tech" v-if="interstPersonList && interstPersonList.length > 0">
									<colgroup>
										<col width="3%">
										<col width="7%">
										<col width="10%"> 
										<col width="8%">
										<col width="12%">
										<col width="8%">
										<col width="12%">
										<col width="10%">
										<col width="*">
									</colgroup>
									<thead>
										<tr>
											<th><input type="checkbox" v-model="chkAll" @change="rowChk('all')" /></th>
											<th>NO</th>
											<th>참여가능여부</th>
											<th>평가등급</th>
											<th>회원명</th>                                                
											<th>기술등급</th>
											<th>경력</th>
											<th>학력-졸업</th>
											<th>직무</th>
										</tr>
									</thead>
									<tbody class="inter">
										<tr v-for="row in interstPersonList" :key="row.indMberSeq" :class="row.indMberSeq == tecMberSeq ? 'click' : ''">
											<td class="score"><input type="checkbox" v-model="row.chkYn" @change="rowChk()" /></td>
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()">{{row.rn}}</td>
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()">
												<!-- <div class="flag_bl">참여가능</div> -->
												<!-- <div class="flag_bl drk">참여불가능</div> -->
												<div class="flag_bl" v-if="row.joinPossYyyymmdd != 'N'">참여가능</div>
												<div class="flag_bl drk" v-else>참여불가능</div>
											</td>
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()">
												<div class="gradeimg">
													<gradeImg type="TOT_SM_EVAL" :grade="row.totEvalGrade" />
												</div>
											</td>
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()">{{row.mberNm}} ({{row.gender}} / {{row.birthYr}}년생)</td>											
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()">{{row.techGradeNm}}</td>
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()">{{row.carYrMth | months}}</td>
											<!-- <td class="score">학력-졸업</td> -->
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()"><SelectComp type="text" :value="row.schoolDivCd" cdId="PRO121"/></td>
											<td class="score" @click="tecMberSeq = row.indMberSeq.toString()"><span class="duty">{{row.duty}}</span></td>
										</tr>
									</tbody>
									<tbody v-if="rowCount != 0 && interstPersonList.length < 9">
										<tr class="empty" v-for="i in 9-interstPersonList.length" :key="i">
											<td></td>
										</tr>
									</tbody>
								</table>
								<!-- 관심인재가 없을 경우 -->
								<div v-else class="project_box type tech">
									<div class="prj_pf none">
										관심인재가 없습니다!
									</div>
								</div>
								
							</div>

							<!-- 페이징부분 -->
							<pagingComp :pageInfo="pageInfo" @page-click="goPage" />
						</template>
					</fixTop>
					<!-- footer -->
					<!-- <footer-layout type="left"/> -->
				</div>
				<!-- 상세보기 -->
				<tec-profile :tecMberSeq="tecMberSeq" v-model="detailObj" @updated="selectIntPrsList()" @profileClose="profileClose()"/>
			</div>
		</div>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from '@/components/highpro/GradeImg.vue';
import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
import fixTop from "@/components/FixTop.vue";


export default {
	props : [ 'res' ],
	data() {
		return {
			interstPersonList : [],
			interstPerson : {},

			srcFilter : {
				pageIndex : '1',
				srcTxt : '',
				ordCol : 'eval',
				sort : 'desc',
				searchFilterList : [],
				joinPossList : [],
                techList : [],
                careerList: [],
                schoolList : [],
			},

			tecMberSeq : '',
			pageInfo : {},
			totCnt : 0,
			chkAll : false,
			rowCount : 0,

			detailObj : {},
			searchCodeList : [],
            searchClick : undefined,
            searchFlag : false,
		};
	},
	
	components : {
		pagingComp, gradeImg, tecProfile, fixTop
	},

	beforeMount() {
		this.selectIntPrsList();
		this.getCodeList();
		
	},
	
	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		// 관심인재 목록 조회
		selectIntPrsList(idx, isScroll = false) {
			var param = this.srcFilter;
			param.pageUnit = 40;
			param.pageSize = 10;
			if(idx) param.pageIndex = idx;

			this.$.httpPost('/api/prj/prj/selectInterestPersonList', param)
				.then(res => {
					//console.log('selectInterestPersonList :', res);

					this.interstPersonList = res.data.interstPersonList;
					this.pageInfo = res.data.pageInfo;
					this.totCnt = res.data.totCnt;

					this.chkAll = false;
					this.rowCount = 40 - this.interstPersonList.length;

					if(isScroll) {
						window.scroll(0, 0);
					}

					if(this.tecMberSeq) this.tecMberSeq = '';
				})
		},

		// 페이지 이동
		goPage(page) {
			this.srcFilter.pageIndex = page;
			this.selectIntPrsList();
		},

		// SORT 변경
		searchOrder(colDiv, sort) {
			this.srcFilter.ordCol = colDiv;
			this.srcFilter.sort = sort;

			this.selectIntPrsList();
		},

		// 체크박스 처리
		rowChk(div) {
			if(div == 'all') {
				for(var i in this.interstPersonList) {
					this.interstPersonList[i].chkYn = this.chkAll;
				}
			}else {
				var chkdRow = 0;
				var chkDiv = true;

				for(var x in this.interstPersonList) {
					if(this.interstPersonList[x].chkYn) {
						chkdRow++;
					}
				}

				if(this.interstPersonList.length != chkdRow) chkDiv = false;
				this.chkAll = chkDiv;
			}
		},

		// 관심인재 삭제처리
		deleteIndPerson() {
			var paramAry = [];

			for(var i in this.interstPersonList) {
				if(this.interstPersonList[i].chkYn) paramAry.push(this.interstPersonList[i]);
			}

			if(paramAry.length > 0) {
				this.$.httpPost('/api/prj/prj/deleteInterestPerson', {indPersonList : paramAry})
					.then(res => {
						if(res.statusText == 'OK') {
							this.srcFilter.srcTxt = '';
							this.selectIntPrsList('1');
						}
					})
			}else {
				alert('선택한 행이 없습니다.');
			}
		},

		// 기술인재 상세 닫기
		profileClose() {
			this.tecMberSeq = '';
			this.detailObj = {};
		},
		getCodeList(){
            this.$.httpPost('/api/mem/srh/getSearchCodeList', this.srcFilter)
                .then(res => {
                    this.searchCodeList = res.data.codeList;
                    // this.getList();
                }).catch(this.$.httpErrorCommon);
        },

		openSearch() {

            if(this.$store.state.userInfo.mberSeq){
                if(!this.searchFlag) {
                    this.searchFlag = true;
                } else {
                    this.searchFlag = false;
                }
            } else {
				this.$.popup('/tec/prj/PRJ101P03')
					.then(res => {
						if(res) {
							// 회원가입 화면으로 이동
							this.$router.push({name : 'MEM003M01'});
						}
					});
            }
        },
		changeClick(idx) {
            this.searchClick = idx;
        },

        allCheck(deta) {
            for(var idx=0; idx < this.srcFilter.searchFilterList.length; idx++) {
                if(deta.cd == this.srcFilter.searchFilterList[idx].cd.substr(0,2)) {
                    var cd = Number(deta.cd);
                    this.searchCodeList[cd-1].count--;
                    this.searchCodeList[cd-1].detailList[this.srcFilter.searchFilterList[idx].detaIndex].checked = false;
                    this.srcFilter.searchFilterList.splice(idx,1);
                    idx--;
                }
            }
        },

        clickDetail(deta, idx) {
            if(deta.checked == undefined || deta.checked == false) {
                if(deta.cd.length == 2) {
                    this.allCheck(deta, idx);
                } else if(this.searchCodeList[Number(deta.cd.substr(0,2))-1].detailList[0].checked) {
                    for(var checkIdx in this.srcFilter.searchFilterList) {
                        if(deta.cd.substr(0,2) == this.srcFilter.searchFilterList[checkIdx].cd) {
                            this.searchCodeList[Number(deta.cd.substr(0,2))-1].count--;
                            this.searchCodeList[Number(deta.cd.substr(0,2))-1].detailList[0].checked = false;
                            this.srcFilter.searchFilterList.splice(checkIdx,1);
                        }
                    }
                }

                if(this.srcFilter.searchFilterList.length > 14) {
                    alert("직무분야는 최대 '15개' 까지 선택 가능합니다.");
                    return;
                }

                var list = {...deta};
                list.cdNm = this.searchCodeList[this.searchClick].cdNm + " > " + deta.cdNm;
                list.detaIndex = idx;
                deta.checked = true;
                this.srcFilter.searchFilterList.push(list);

                if(isNaN(this.searchCodeList[this.searchClick].count) || this.searchCodeList[this.searchClick].count == 0) {
                    this.searchCodeList[this.searchClick].count = 1;
                } else {
                    this.searchCodeList[this.searchClick].count++;
                }

            } else {
                deta.checked = false;
                this.searchCodeList[this.searchClick].count--;
                for(var li = 0; li < this.srcFilter.searchFilterList.length; li++) {
                    if(this.srcFilter.searchFilterList[li].cd == deta.cd) {
                        this.srcFilter.searchFilterList.splice(li,1);
                        break;
                    }
                }
            }   
        },

		resetSearchFilter() {
            for(var list of this.srcFilter.searchFilterList) {
                var cd = Number(list.cd.substr(0,2));
                this.searchCodeList[cd-1].count--;
                this.searchCodeList[cd-1].detailList[list.detaIndex].checked = false;
            }
            this.srcFilter.searchFilterList.splice(0,this.srcFilter.searchFilterList.length);
        },

	}
}
</script>